import { 
  Flex, 
  Image, 
  Box, 
  Container, 
  Tabs, 
  TabList, 
  TabPanels, 
  Tab, 
  TabPanel,
  HStack,
  Text,
  Spacer,
  Heading,
  Button,
  ButtonGroup,
} from '@chakra-ui/react'

import { Placeholder } from './Placeholder'
import { useNavbar } from '../hooks/useNavbar'
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import ConnectWallet from './ConnectWallet'

import logo from "../assets/logo.png"
import styled from '@emotion/styled'

const Logo = styled.div`
  width: 100%;
  padding: 20px 0px 0 20px;
  margin: 0px 0 0px 0 ;
`

export const Navbar = ({setAccount, setWallet, setProvider, setChainId}) => {
  const { rootProps } = useNavbar()
  return (
    <Box
      as="nav"
      role="navigation"
      position="sticky"
      top="0"
      zIndex="docked"
      bg="bg.accent.default"
      {...rootProps}
    >
      <Flex minWidth='max-content' alignItems='center' gap='2'>
        <Box p='2'>
          <Heading size='md'>
          <Logo>
            <Image src={logo} width="50px" />
          </Logo>
          </Heading>
        </Box>

        <Box ml={"14%"} mt={"25px"} >
        <HStack spacing='20px'>
             <Heading fontSize={25}>Perezoso Admin Dashboard </Heading>
           {/* <Text fontSize='lg' fontWeight='bold' color='text.primary'>
            Borrow
          </Text>
          <Text fontSize='lg' fontWeight='bold' color='text.primary'>
            Liquidity
          </Text> */}
          </HStack>
        </Box>

        <Spacer />
        <ButtonGroup gap='10' >
          <ConnectWallet 
            setAccount={setAccount} 
            setWallet={setWallet} 
            setProvider={setProvider} 
            setChainId={setChainId}
            style={{marginRight: '40%'}}
          />
          <Box>
          </Box>
        </ButtonGroup>
      </Flex> 
    </Box>
  )
}