import React, { useState, useEffect, useMemo } from 'react';
import { 
  useColorMode,
  Heading, 
  Box, 
  Container, 
  Grid,
  GridItem,
  IconButton, 
  Text,
  Input,
  Button,
  Switch,
  Flex,
  Link,
  HStack,
  VStack,
  Slider,
  SliderMark,
  SliderTrack,
  SliderThumb,
  SliderFilledTrack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,  
  useDisclosure,
  Spacer,
  Divider
} from '@chakra-ui/react';
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  ButtonGroup
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaAngleDown, FaAngleUp, FaArrowCircleRight } from 'react-icons/fa';
import { ethers } from "ethers";
import { useConnectWallet } from '@web3-onboard/react'
import MyChart from './MyChart'; // The path to your chart component

import useDistribution from '../hooks/useDistribution';
import bnbLogo from "../assets/bnblogo.png" 
import logoPRZS from "../assets/logo.png" 

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Select,
} from "@chakra-ui/react"
import { mode } from '@chakra-ui/theme-tools' 
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { getAddress, getABI, tickToPrice, chainIdToNetwork, commify, extractErrorMessage } from "../utils"

const { utils } = ethers;
const { formatEther, formatUnits, parseEther } = utils;

const BASE_FEE = 10000;

const ABI = [
  "function approve(address spender, uint256 amount) public returns (bool)",
  "function getSpotPrice(address vault) external view returns (uint256 spotPrice)",
  "function totalSupply() view returns (uint)", 
  "function balanceOf(address) view returns (uint)",
  "function getUnderlyingBalances() view returns (uint,uint)",
  "function allowance(address owner, address spender) public view returns (uint256)",
  "function decimals() public view returns (uint8)",
]

const ADDRESS_ZERO = "0x0000000000000000000000000000000000000000";

function sleep(ms) {  
  return new Promise(resolve => setTimeout(resolve, ms));
}

const handleCopyAddress = (address) => {
  navigator.clipboard.writeText(address);
}

export const Main = ({ wallet, account, provider, chainId }) => {
  
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedTier, setSelectedTier] = useState("0"); 
  const [selectedTierRewards, setSelectedTierRewards] = useState(0);
  const [isAddingAddress, setIsAddingAddress] = useState(false);
  const [isEditingReward, setIsEditingReward] = useState(false);
  const [isBulkTransferring, setIsBulkTransferring] = useState(false);
  const [isRemovingAddress, setIsRemovingAddress] = useState(false);
  const [isEmptyingList, setIsEmptyingList] = useState(false);
  const [inputValue, setInputValue] = useState(""); // State to hold the input value
  const [bnbBalance, setBNBBalance] = useState(0);
  const [tier1Addresses, setTier1Addresses] = useState([]);
  const [przsBalance, setPrzsBalance] = useState(0);
  const [distributionAddress, setDistributionAddress] = useState("");
  const [data, setData] = useState({tier1: {addresses: [], rewards: 0}});
  const [tiersData, setTiersData] = useState({0: {tier: 0, reward: "0", addresses: []} });
  const [isSendModalOpen, setSendModalOpen] = useState(false);
  const [isEmptyListModalOpen, setEmptyListModalOpen] = useState(false);
  
  const [vaults, setVaults] = useState([]);
  const [isExecutingShift, setIsExecutingShift] = useState(false);
  const [signer, setSigner] = useState();
  
  const network = chainIdToNetwork(chainId) || "ganache";
  const bscScanURL = `https://bscscan.com/address/${distributionAddress}`;

   // Function to handle the change in the input field
   const handleInputChange = (event) => {
    setInputValue(event.target.value); // Update the state with the new input value
  };
  
  useEffect(() => {
    const fetchData = async () => {
      if (isEditingReward) return;

      const Distribution = await import(`../deployments/Distribution.sol/Distribution.json`);
      const DistributionAbi = Distribution.abi;
      
      const distributionAddress = await getAddress("Distribution", network);
      setDistributionAddress(distributionAddress);

      const DistributionContract = new ethers.Contract(
          distributionAddress,
          DistributionAbi, 
          provider
      );
      
      try {

        const tier1Addresses = await DistributionContract.getTierAddresses(0);
        const tier2Addresses = await DistributionContract.getTierAddresses(1);
        const _tier1Rewards = await DistributionContract.getTierRewards(0);
        const _tier2Rewards = await DistributionContract.getTierRewards(1);

        const przsBalance = await DistributionContract.getBalance();

        setPrzsBalance(przsBalance);

        if (tier1Addresses.length > 0) {
          setTiersData({
              "0" : {
                tier: 0,
                reward: commify(formatEther(_tier1Rewards), 0),
                addresses: tier1Addresses,
                total: tier1Addresses.length
              },
              "1": {
                tier: 1,
                reward: commify(formatEther(_tier2Rewards), 0),
                addresses: tier2Addresses,
                total: tier2Addresses.length
              }
          });
        }

      } catch (error) {
        console.log(error);
        setIsEditingReward(false);
      }

    } 

    fetchData();

    const intervalId = setInterval(fetchData, 5000);
    return () => clearInterval(intervalId)

  }, [network, isEditingReward, provider, selectedTier]);

  useEffect(() => {
    const fetchBalance = async () => {
        if (account && provider) {
            try {
                const bnbBalance = await provider.getBalance(account.address);
                setBNBBalance(bnbBalance);
            } catch (error) {
                console.error("Failed to fetch balance:", error);
            }
        }
    };

    fetchBalance();
}, [account, provider]);


  const handlAddAddress = async (selectedTier) => {
    if (!provider) return;

    const network = chainIdToNetwork(provider._network.chainId);

    const distributionAbi = await getABI("Distribution", network); // Your actual async operation
    const distributionAddress = await getAddress("Distribution", network); // Your actual async operation

    setIsAddingAddress(true);

    const signer = provider?.getSigner();
    await provider.send('eth_requestAccounts', []); // <- this promps user to connect metamask

    const distribution = new ethers.Contract(distributionAddress, distributionAbi, signer);
  
    try {
      if (ethers.utils.isAddress(inputValue)) {
          const tx = await distribution.addAddressToTier(selectedTier, inputValue);
          await tx.wait();
          toast.success("Address added successfully");
          setIsAddingAddress(false);

      } else {
          toast.error("Invalid address");
          setIsAddingAddress(false);
          throw new Error("Invalid address");
      }

    } catch (error) {
        console.log(error);
        setIsAddingAddress(false);
    }      
  }
 
  const handleRemoveAddress = async (address, selectedTier) => {
    
    console.log(`Address to remove is ${address}`)

    if (!provider) return;
    const network = chainIdToNetwork(provider._network.chainId);

    const distributionAbi = await getABI("Distribution", network); // Your actual async operation
    const distributionAddress = await getAddress("Distribution", network); // Your actual async operation

    setIsRemovingAddress(true);

    const signer = provider.getSigner();
    const distribution = new ethers.Contract(distributionAddress, distributionAbi, signer);
  
    try {
      if (ethers.utils.isAddress(address)) {
        const tx = await distribution.removeAddressFromTier(selectedTier, address);
        await tx.wait();
        toast.success("Address removed successfully");
        setIsRemovingAddress(false);

      }
    } catch (error) {
      console.log(error);
      setIsRemovingAddress(false);
    }      
  }

  const handleSetTierRewards = async (selectedTier, amount) => {
    if (!provider) return;
    const network = chainIdToNetwork(provider._network.chainId);

    const distributionAbi = await getABI("Distribution", network); // Your actual async operation
    const distributionAddress = await getAddress("Distribution", network); // Your actual async operation

    setIsEditingReward(true);

    const signer = provider.getSigner();
    const distribution = new ethers.Contract(distributionAddress, distributionAbi, signer);

    try {
      console.log(`Setting tier rewards for ${selectedTier} to ${amount}`)
      
      const gasEstimate = await distribution.estimateGas.setTierRewards(selectedTier, parseEther(`${amount}`));
      console.log(`Gas estimate: ${gasEstimate.toString()}`);
      
      const tx = await distribution.setTierRewards(selectedTier, parseEther(`${amount}`));
      await tx.wait();
      toast.success("Tier rewards set successfully");
      setIsEditingReward(false);
    } catch (error) {
      console.log(error.message)
      toast.error(extractErrorMessage(error.message));
      console.log(error);
      setIsEditingReward(false);
    }

  }

  const handleRecoverFunds = async () => {
    if (!provider) return;

    const network = chainIdToNetwork(provider._network.chainId);

    const distributionAbi = await getABI("Distribution", network); // Your actual async operation
    const distributionAddress = await getAddress("Distribution", network); // Your actual async operation

    setIsBulkTransferring(true);

    const signer = provider.getSigner();
    const distribution = new ethers.Contract(distributionAddress, distributionAbi, signer);

    try {
      const tx = await distribution.recoverTokens();
      await tx.wait();
      toast.success("Funds recovered successfully");
      setIsBulkTransferring(false);
    } catch (error) {
      toast.error("Funds recovery failed");
      console.log(error);
      setIsBulkTransferring(false);
    }

  }

  const handleBulkTransfer = async (tier) => {
    if (!provider) return;
    const network = chainIdToNetwork(provider._network.chainId);

    const distributionAbi = await getABI("Distribution", network); // Your actual async operation
    const distributionAddress = await getAddress("Distribution", network); // Your actual async operation

    setIsBulkTransferring(true);

    const signer = provider.getSigner();
    const distribution = new ethers.Contract(distributionAddress, distributionAbi, signer);

    try {
      await sleep(2000);
      const tx = await distribution.bulkTransfer(tier);
      await tx.wait();
      toast.success("Bulk transfer completed successfully");
      setIsBulkTransferring(false);
    } catch (error) {
      toast.error(extractErrorMessage(error.message));
      console.log(error);
      setIsBulkTransferring(false);
    }

  } 

  const handleEmptyList = async () => {
    
      if (!provider) return;
      const network = chainIdToNetwork(provider._network.chainId);

      const distributionAbi = await getABI("Distribution", network); // Your actual async operation
      const distributionAddress = await getAddress("Distribution", network); // Your actual async operation

      setIsEmptyingList(true);

      const signer = provider.getSigner();
      const distribution = new ethers.Contract(distributionAddress, distributionAbi, signer);

      try {

        const tx = await distribution.removeAllAddresses();
        await tx.wait();

        setTiersData({
          "0" : {
            tier: 0,
            reward: commify(tiersData[selectedTier]?.reward),
            addresses: []
          },
          "1": {
            tier: 1,
            reward: 0,
            addresses: []
          }
      });

        toast.success("Empty list completed successfully");
        setIsEmptyingList(false);
        
      } catch (error) {
        toast.error("Empty list failed");
        console.log(error);
        setIsEmptyingList(false);
      }    
  }
  
  return (
  <>
  <ToastContainer position="top-center" theme="dark" />
  <Container maxW="1024px" px={{ base: '10px', md: '0' }}  mt={10} bgColor={"#203419"}>
    <Box style={{backgroundColor:'#304c20'}} >
       {account?.address ?
        <HStack>         
          <Heading size="sm"> 
          <HStack>
            <p>Welcome</p> 
            <a target="_blank" href={`https://bscscan.com/address/${account?.address}`}>
            <p style={{padding: "5px 0 5px 5px", fontSize: "10", color:"gray", width:"220px", backgroundColor:"#203419", borderRadius:"5px" }}>
              {account?.address.substr(0,11)}...${account?.address.substr(33,42)}
            </p>
            </a>
            <p>
              |&nbsp;&nbsp;Balance 
            </p>
            <p style={{padding: "5px 0 5px 5px", color:"gray", width:"120px", backgroundColor:"#203419", borderRadius:"5px" }}>
              {Number(formatEther(`${bnbBalance}`)).toFixed(4)}
            </p>
            &nbsp;<img src={bnbLogo} width="20px"></img>
            <p style={{fontSize:"15px"}}>
            (BNB)
            </p> 
            </HStack>
          </Heading>
          <Flex minWidth='max-content' alignItems='center' gap='2' ml="25%">
          <a href="..." style={{marginLeft:"auto"}}>History</a>
          <Spacer />
          <ButtonGroup gap='2'>
          <Button 
              bgColor={"#b93c4f"} 
              size={"sm"}
              onClick={() => handleRecoverFunds(selectedTier)}
              isDisabled={account == null || selectedTier == "" }
              isLoading={isBulkTransferring}
              style={{marginRight:"5px"}}
            >
              Recover funds
            </Button>
          </ButtonGroup>
        </Flex>
        </HStack> :
        <></>}
      <br />
    </Box>
    <Box>
    <Tabs  variant="colorful" style={{border:"1px solid white", borderRadius: "10px"}}>
    <TabList>
      <Tab>Rewards</Tab>
      <Tab>Staking</Tab>
      <Tab>Airdrop</Tab>
    </TabList>

    <TabPanels>
      <TabPanel>
        <Box  style={{padding: "15px 0 10px 20px"}}  >
          <Heading fontSize={20} mb={2}>Rewards Distribution</Heading>

            <Grid templateColumns='repeat(2, 1fr)' gap={6} >
              <GridItem w='auto' h='auto'>
                <Box style={{marginTop:"20px"}} >
                <HStack spacing='24px'>
                  <Box w={20}>
                    <Text color="gray">Select tier: </Text>
                  </Box>
                <Box>
                <Select 
                  placeholder='Choose tier' 
                  defaultValue={0}
                  width={250} 
                  onChange={(ev) => setSelectedTier(ev.target.value)} 
                  isDisabled={account == null}
                >
                  <option value='0'>Tier 1 {`(${tiersData[0]?.reward})`}</option>
                  <option value='1'>Tier 2 {`(${tiersData[1]?.reward})`}</option>
                </Select>
                </Box>
                </HStack>
              </Box>
              {
            selectedTier != "" ?
          <>
            {selectedTier && (
              <Box marginTop={2}>
                <Heading mt={5} mb={5} fontSize={18}>Edit Tier</Heading>
                <HStack>
                  <Box w={95}>
                      <Text color="gray">Address: </Text>
                  </Box> 
                  <Box>
                    <Input
                      w={250} 
                      placeholder='0x...' 
                      value={inputValue} // Controlled component
                      onChange={handleInputChange} // Handle input changes
                    />
                  </Box>
                  <Box>
                    <Button 
                      ml={5}
                      size={"sm"}
                      bgColor={"#478b30"} 
                      onClick={() => handlAddAddress(selectedTier)} 
                      isLoading={isAddingAddress} 
                      isDisabled={account == null || selectedTier == ""}
                    >
                      Add
                    </Button>
                </Box>
                </HStack>
                <HStack>
                  <Box w={95}>
                      <Text color="gray">Amount (<b>PRZS</b>): </Text>
                  </Box> 
                  <Box>
                  <NumberInput 
                    min={0}
                    step={10000}
                    w={250}
                    value={selectedTierRewards}
                    onChange={(value) => setSelectedTierRewards(value)} 
                  >
                    <NumberInputField/>
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  </Box>
                  <Box>
                    <Button
                      ml={5} 
                      bgColor={"#478b30"} 
                      size={"sm"}
                      onClick={() => handleSetTierRewards(selectedTier, selectedTierRewards)} 
                      isLoading={isEditingReward} 
                      isDisabled={account == null || selectedTier == ""}
                    >
                      Edit
                    </Button>
                </Box>
                </HStack>            
                <Box marginTop={20}>
                  <>
                  <Spacer  />
                  {/* <Divider /> */}
                  <Box mt={10}> 
                    <HStack>
                    <Heading fontSize={18}>
                      <p>Addresses</p> 
                        <Box>
                        <HStack>
                        <Text mt={2} fontSize={14} color="gray">
                            [Tier {selectedTier == 0 ? 1 : 2},{` total: ${tiersData[selectedTier]?.addresses.length}`}]
                          </Text>
                        </HStack>
                        </Box>
                    </Heading>
                    &nbsp;
                    {tiersData[selectedTier]?.addresses.length > 0 ? 
                      <Button
                        ml="220px" 
                        size="sm"
                        bgColor={"#b93c4f"}
                        onClick={() => setEmptyListModalOpen(true)}
                        isDisabled={account == null || selectedTier == ""}
                      >
                        Empty list
                      </Button> : 
                    <></>}                
                    </HStack>
                    {/* icon trash */}
                    <Modal isOpen={isEmptyListModalOpen} onClose={() => setEmptyListModalOpen(false)} >
                    <ModalOverlay />
                    <ModalContent  style={{backgroundColor:'#304c20'}}>
                      <ModalHeader>Empty list</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        {`Are you sure you want to remove ${Number(tiersData[selectedTier]?.total)} addresses from the Tier ${selectedTier == 0 ? 1 : 2} list?`}
                      </ModalBody>
                      <ModalFooter>
                        <Button backgroundColor="#478b30" mr={3} onClick={() => setEmptyListModalOpen(false)}>
                          Close
                        </Button>
                        <Button 
                          backgroundColor="#478b30" 
                          isLoading={isEmptyingList} 
                          onClick={async() => {handleEmptyList(); await sleep(2000)}}
                        >
                          Yes
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                  </Box>
                  <Table style={{ marginTop: "20px" }} variant="simple">
                    {tiersData[selectedTier]?.addresses.map((address, index) => {
                      if (address != ADDRESS_ZERO) { 
                        return ( 
                          <Tr key={index}> {/* Adding a key for each row */}
                            <Td width={10}>{address.substr(0,12)}......${address.substr(30,42)}</Td> 
                            <Td> 
                              <Button 
                                bgColor={"#478b30"} 
                                size="sm"
                                isLoading={isRemovingAddress}
                                onClick={() => handleRemoveAddress(address, selectedTier)}
                                isDisabled={account == null}
                                >
                                Remove
                              </Button> 
                            </Td>
                          </Tr>
                        )
                      } else {
                        return <></>
                      }  
                    })}
                  </Table>
                  </> 
                  {/* } */}
                </Box>
              </Box>
            )}
          </> : 
            <Box mt={50}>No Data</Box>}
            </GridItem>
            <GridItem w='auto' h='auto' > 
              <Box>
              <Heading fontSize={18} mt={5}>Contract info</Heading>            

              <HStack mt={5}>  
                <Text color="gray"> Contract: </Text>
                {/* @ts-ignore */}
                <a target="_blank" href={`${bscScanURL}`} style={{marginLeft:"50px", color:"gray"}}><Text>{`${distributionAddress.substr(0,8)}...${distributionAddress.substr(28,42)}`}</Text></a>
                <Button size="sm" onClick={() => handleCopyAddress(distributionAddress)} style={{marginLeft:"10px"}}><p style={{fontSize:"11px"}}>Copy</p></Button>
              </HStack>
              <HStack mt={5}>  
                <Text color="gray"> Balance: </Text>
                <Text>
                  <HStack style={{marginLeft:"50px"}}>
                    <p style={{padding:"5px 0 5px 10px", width:"180px", border:"1px solid white", borderRadius:"10px", backgroundColor:"#304c20"}}>
                      {Number(formatEther(`${przsBalance}`)).toFixed(2)} 
                    </p>&nbsp;
                    <img style={{marginLeft:"5px"}} src={logoPRZS} width="25px"></img>
                  </HStack>
                </Text>
                <Text>(<b>PRZS</b>)</Text>
              </HStack>
              </Box>
              <Box>
              <Heading fontSize={18} mt={35}>Manage funds</Heading>
              <HStack mt={5}>  
                <Text color="gray"> Amount (<b>PRZS</b>)</Text>
                &nbps;&nbps;
                <NumberInput  
                  defaultValue={"--"}
                  w={"180px"} 
                  min={0}
                  value={!isNaN(selectedTierRewards) ? selectedTierRewards : "--"}
                >
                  <NumberInputField/>
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Button 
                  ml={5}
                  bgColor={"#478b30"} 
                  size={"sm"}
                  onClick={() => setSendModalOpen(true)}
                  isDisabled={account == null || selectedTier == "" }
                  isLoading={isBulkTransferring}
                >
                  Send
                </Button>
                <Modal isOpen={isSendModalOpen} onClose={() => setSendModalOpen(false)} >
                <ModalOverlay />
                <ModalContent style={{backgroundColor:'#304c20'}}>
                  <ModalHeader>Send all</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    {`Send ${commify(Number(tiersData[selectedTier]?.reward.replace(/,/g, "")) * Number(tiersData[selectedTier]?.total), 0)} PRZS to ${Number(tiersData[selectedTier]?.total)} addresses?`}
                  </ModalBody>
                  <ModalFooter>
                    <Button backgroundColor="#478b30" mr={3} onClick={() => setSendModalOpen(false)}>
                      Close
                    </Button>
                    <Button
                      isLoading={isBulkTransferring}
                      backgroundColor="#478b30" 
                      onClick={() => {handleBulkTransfer(selectedTier); onClose()}}
                    >
                      Yes
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
                </HStack> 
                &nbsp;

                <Spacer />
                {/* <Divider /> */}
              </Box>
            </GridItem>
            </Grid>
        </Box>
      </TabPanel>
      <TabPanel>
        <p>Staking</p>
      </TabPanel>
      <TabPanel>
        <p>Airdrop</p>
      </TabPanel>
    </TabPanels>
  </Tabs>

    </Box>

  </Container>
  { account == null ? 
    <Box w={"60%"} marginLeft={"20%"} marginTop={5}> 
      <Alert status='error'>
          <AlertIcon />
          <AlertTitle>Wallet not logged in</AlertTitle>
          <AlertDescription>Your must login with your wallet to use this page.</AlertDescription>
      </Alert> 
    </Box> : <></>}  
  </>  
  )
}
