import { Box, Container } from '@chakra-ui/react'
import { Placeholder } from './Placeholder'

export const Footer = (props) => {
  return (
    <Box as="footer" role="contentinfo" bg="bg.accent.default" {...props}>
      <Container style={{textAlign:"center"}}>
        <Placeholder minH="20"> Perezoso Token 2024 </Placeholder>
      </Container>
    </Box>
  )
}
