import { tabsAnatomy } from '@chakra-ui/anatomy'
import { mode } from '@chakra-ui/theme-tools' // import utility to set light and dark mode props
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys)

// define a custom variant
const colorfulVariant = definePartsStyle((props) => {

    const { colorScheme: c } = props // extract colorScheme from component props

  return {
    tab: {
      border: '2px solid',
      borderColor: 'transparent',
      // use colorScheme to change background color with dark and light mode options
      bg: "transparent",
      borderTopRadius: 'lg',
      border: 'none',
      borderTop: 'none',
      borderBottom: 'none',
      _selected: {
        bg: mode('#fff', '#203419')(props),
        color: mode(`${c}.500`, `#fff`)(props),
        borderColor: 'gray',
        mb: '-2px',
        border: 'none',
        borderBottom: '1px solid white'
      },
    },
    tablist: {
      borderBottom: '2x solid',
      borderColor: 'inherit',
      borderTop: '0px solid',
      border: 'none',
    },
    tabpanel: {
      border: '2px solid',
      borderColor: 'inherit',
      borderBottomRadius: '0',
    },
  }
})

const variants = {
  colorful: colorfulVariant,
}

// export the component theme
export const tabsTheme = defineMultiStyleConfig({ variants })