
import injectedModule from '@web3-onboard/injected-wallets'

const INFURA_KEY = ''

const injected = injectedModule()

const trezorOptions = {
  email: 'test@test.com',
  appUrl: 'https://www.blocknative.com'
}

export const wallets = [
  injected
]

export const chains = [
  {
    id: '0xA',
    token: 'OETH',
    label: 'Optimism',
    rpcUrl: `https://opt-mainnet.g.alchemy.com/v2/${process.env.REACT_ALCHEMY_ID}`
  }
]

export const appMetadata = {
  name: 'Amphor xyz',
  icon: '<svg>My App Icon</svg>',
  description: 'Algorithmic market making',
  recommendedInjectedWallets: [
    { name: 'MetaMask', url: 'https://metamask.io' },
    { name: 'Coinbase', url: 'https://wallet.coinbase.com/' }
  ]
}
