import { useEffect, useState, useMemo } from 'react';
import { ethers } from 'ethers';
import { getAddress } from "../utils";

const {formatEther} = ethers.utils;

const ERC20Abi = [
    "function totalSupply() view returns (uint)", 
    "function balanceOf(address) view returns (uint)",
]

const useDistribution = (deployerAddress, network) => {

    const [tier1Addresses, setTier1Addresses] = useState([]);
    const [tier1rewards, setTier1Rewards] = useState([]);
    const [przsBalance, setPrzsBalance] = useState(0);
    const [distributionAddress, setDistributionAddress] = useState("");

    // Setup Provider
    const provider = useMemo(() => new ethers.providers
    .JsonRpcProvider(
      network == "ganache" ? "http://localhost:8545" : process.env.REACT_APP_PROVIDER_URL 
    ), 
      [network == "ganache" ? "http://localhost:8545" : process.env.REACT_APP_PROVIDER_URL]
    ); 

    useEffect(() => {

        const fetchData = async () => {

            const Distribution = await import(`../deployments/Distribution.sol/Distribution.json`);
            const DistributionAbi = Distribution.abi;
            
            console.log(`Network is ${network}`)
            const distributionAddress = await getAddress("Distribution", network);
            setDistributionAddress(distributionAddress);

            const DistributionContract = new ethers.Contract(
                distributionAddress,
                DistributionAbi, 
                provider
            );
            
            const tier1ddresses = await DistributionContract.getTierAddresses(0);
            const przsBalance = await DistributionContract.getBalance();
            const tier1Rewards =  await DistributionContract.getTierRewards(0);

            setPrzsBalance(przsBalance);
            setTier1Addresses(tier1ddresses);
            setTier1Rewards(tier1Rewards);
        } 



        fetchData();

        // Refresh data every 5 seconds
        const intervalId = setInterval(fetchData, 5000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId)
    }, [deployerAddress, provider, network]);  // Added network to the dependency array

    return { data: {tier1: {addresses: tier1Addresses,rewards: tier1rewards}}, przsBalance, distributionAddress};
}

export default useDistribution;
