import { Button } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useConnectWallet } from '@web3-onboard/react'
import { ethers, providers } from 'ethers'


//@ts-ignore
export default function ConnectWallet({setAccount, setWallet, setProvider, setChainId}) {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()

  useEffect(() => {
    if (wallet?.provider) {
      const { name, avatar } = wallet?.accounts[0].ens ?? {}
      setAccount({
        address: wallet.accounts[0].address,
        balance: wallet.accounts[0].balance,
        ens: { name, avatar: avatar?.url }
      })
       const ethersProvider = new ethers.providers.Web3Provider(wallet.provider)
      ethersProvider.getNetwork().then(network => {
        setChainId(network.chainId)
      })
    }
  }, [wallet])

  useEffect(() => {
    // If the wallet has a provider than the wallet is connected
    if (wallet?.provider) {
      setProvider(new providers.Web3Provider(wallet.provider, 'any'))
      // if using ethers v6 this is:
      // ethersProvider = new ethers.BrowserProvider(wallet.provider, 'any')
    }
  }, [wallet])

  useEffect(() => {
    // If the wallet has a provider then the wallet is connected
    if (wallet?.provider) {
      setProvider(new providers.Web3Provider(wallet.provider, 'any'))

      const handleAccountsChanged = async (accounts) => {
        if (accounts.length === 0) {
          // MetaMask is locked or the user has not connected any accounts
          console.log('No accounts found');
        } else if (accounts[0] !== wallet.accounts[0].address) {
          // Here accounts[0] is the account address
          console.log('Account changed to', accounts[0]);
      
          // You could potentially fetch more data about the account here
      
          // Update your state with the new account details
          const { name, avatar } = wallet?.accounts[0].ens ?? {};
      
          setAccount({
            address: accounts[0],
            balance: wallet.accounts[0].balance,
            ens: { name, avatar: avatar?.url }
          });
      
          setWallet(wallet);
      
          setProvider(new providers.Web3Provider(wallet.provider, 'any'));
        }
      };    

      wallet.provider.on("accountsChanged", handleAccountsChanged);
      wallet.provider.on('chainChanged', handleChainChanged);

      function handleChainChanged(chainId) {
        console.log(`Chain switched to ${chainId}`)
        setChainId(chainId)
      } 

      return () => {
          wallet.provider.removeListener("accountsChanged", handleAccountsChanged);
          wallet.provider.removeListener('chainChanged', handleChainChanged);
      };

    }
  }, [wallet])

  return (
    <>
      {!wallet?.provider ? 
      <Button
        mt={5}
        disabled={connecting}
        onClick={() => connect()}>
        Connect
      </Button>
      : <></>}
    </>
  )
}