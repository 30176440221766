import {   
  ChakraProvider,
  theme,
  CSSReset,
  Box,
  Center,
  Text,
  extendTheme} from '@chakra-ui/react'
import { Web3OnboardProvider, init } from '@web3-onboard/react'
import { wallets, chains, appMetadata } from './config/onboarding'

import { Footer } from './components/Footer'
import { Main } from './components/Main'
import { Navbar } from './components/Navbar'
// import "./App.css";
import { useState, useMemo } from 'react'
import { ethers } from 'ethers'
import { chainIdToNetwork } from './utils'
import { mode } from "@chakra-ui/theme-tools"
import { tabsTheme } from './components/TabsTheme'

const web3Onboard = init({
  wallets,
  chains,
  appMetadata
})

export const customTheme = extendTheme({
  styles: {
    global: (props) => ({
      "html, body": {
        background: mode("white", "#304c20")(props),  //mode(light mode color, dark mode color)
      },
    }),
  },
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  components: {
    Tabs: tabsTheme,
  },
})

function App() {

  const [account, setAccount] = useState(null)
  const [tokens, setTokens] = useState([]);
  const [wallet, setWallet] = useState(null)
  const [provider, setProvider] = useState(null)
  const [chainId, setChainId] = useState(56)

  // Setup Provider
  const localProvider = useMemo(() => new ethers.providers
  .JsonRpcProvider(
    chainIdToNetwork(chainId) == "ganache" || chainId == null ? "http://localhost:8545" : process.env.REACT_APP_PROVIDER_URL 
  ), 
    [chainIdToNetwork(chainId)]
  ); 
  
  return (
    <ChakraProvider theme={customTheme}>
      <CSSReset />
      <Web3OnboardProvider web3Onboard={web3Onboard}>
          <Box display="flex" flexDirection="column" minHeight="100vh">
            <Navbar  setAccount={setAccount} setWallet={setWallet} setProvider={setProvider} setChainId={setChainId} />
             <Main wallet={wallet} account={account} provider={provider || localProvider} chainId={chainId} flex="1" /> 
            <Footer marginTop="auto" />
          </Box>
      </Web3OnboardProvider>
    </ChakraProvider>
  );
}


export default App;
